import React from "react"
import { ExternalLinkIcon } from "@heroicons/react/solid"

// Source: https://images.unsplash.com/photo-1571624436279-b272aff752b5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&sat=-100
import OfficeChairs from '../components/images/OfficeChairs'
import { DEMO_URL } from "../constants"

const LetsTalk = () => (
  <div>
    <div className="relative bg-gray-900">
      <div className="relative h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <OfficeChairs />
        <div
          aria-hidden="true"
          className="absolute inset-0 bg-gradient-to-r from-teal-500 to-cyan-600"
          style={{ mixBlendMode: 'multiply' }}
        />
      </div>
      <div className="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <h2 className="text-base font-semibold uppercase tracking-wider text-gray-200">
            Let's Talk
          </h2>
          <p className="heading-primary text-gray-100">We want to learn about your customer success goals.</p>
          <p className="mt-3 text-lg text-gray-200">
            Schedule a conversation to talk about your customer success goals and to see if Clove is a fit for you.
          </p>
          <div className="mt-8">
            <div className="inline-flex rounded-md shadow">
              <a
                href={DEMO_URL}
                target="_blank"
                rel="noreferrer"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
              >
                Schedule a Conversation
                <ExternalLinkIcon className="-mr-1 ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default LetsTalk
