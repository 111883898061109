import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = () => (
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "office-chairs.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 780) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Img className="w-full h-full object-cover" fluid={data.image.childImageSharp.fluid} />}
  />
)
export default Image
