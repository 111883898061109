import React from 'react'
import { CheckIcon } from '@heroicons/react/outline'

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import LetsTalk from '../../components/LetsTalk'

const FEATURE_COLUMN_SIZE = 4

const features = [
  'Embed metrics from your business intelligence tool',
  'Curate content that drives adoption',
  'Completely customize spaces for each customer',
  'Track which users engage with which content',
  // Column
  'Live BI metrics, no more copy pasting',
  'Embed PDFs, spreadsheets, and presentations',
  'Ensure success for new users at existing accounts',
  'Detailed access control for spaces and all content',
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Renewals(props) {
  return (
    <Layout>
      <Seo title="Renewals Use Case | Clove" />
      <div className={"relative bg-white py-16"}>
        <div className={"mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl"}>
          <h2 className="heading-primary">Streamline the renewals process with Clove.</h2>

          <div className="max-w-prose mt-8 space-y-2 text-primary leading-relaxed">
            <p>
              You've put in the work, your customer has been successful. You have a plan for how to make them
              even more successful. It's time to make sure they know it.
            </p>

            <p>
              Build your quarterly business reports in Clove with embedded metrics, action plans, and
              the right success content at the right time.
            </p>
          </div>
        </div>
      </div>

      <div className="relative bg-gray-50 py-16 sm:py-24 lg:py-32">
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <div className="xl:grid xl:grid-cols-3 xl:gap-x-8">
            <div>
              <h2 className="text-base font-semibold text-blue-600 uppercase tracking-wide">Success starts here</h2>
              <p className="mt-2 text-3xl font-extrabold text-gray-900">Renewal Features</p>
              <p className="mt-4 text-lg text-gray-500">
                Build a world-class success experience that's focused on what matters most: your customers.
              </p>
            </div>
            <div className="mt-4 sm:mt-8 md:mt-10 md:grid md:grid-cols-2 md:gap-x-8 xl:mt-0 xl:col-span-2">
              <ul className="divide-y divide-gray-200">
                {features.slice(0, FEATURE_COLUMN_SIZE).map((feature, featureIdx) => (
                  <li key={feature} className={classNames(featureIdx === 0 ? 'md:py-0 md:pb-4' : '', 'py-4 flex')}>
                    <CheckIcon className="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true" />
                    <span className="ml-3 text-sm text-gray-500">{feature}</span>
                  </li>
                ))}
              </ul>

              <ul className="border-t border-gray-200 divide-y divide-gray-200 md:border-t-0">
                {features.slice(FEATURE_COLUMN_SIZE).map((feature, featureIdx) => (
                  <li
                    key={feature}
                    className={classNames(featureIdx === 0 ? 'md:border-t-0 md:py-0 md:pb-4' : '', 'py-4 flex')}
                  >
                    <CheckIcon className="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true" />
                    <span className="ml-3 text-sm text-gray-500">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <LetsTalk />
    </Layout>
  )
}
